<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12>
        <span> <h3 style="border-bottom: 3px solid grey">ANIMAL RESCUE</h3> </span>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-4 justify-end >
      <v-flex lg8 pa-1>
        <v-layout wrap>
          <v-flex lg6>
            
             <v-select outlined  clearable    dense label="Division" :items="divisionList" item-text="divisionName" item-value ="_id" v-model="division">
            </v-select>  
            </v-flex>
          <v-flex lg6 pl-1>
             <v-select outlined clearable   dense label="Range" :items="rangeList" item-text="rangeName" item-value ="_id" v-model="range">
            </v-select>  
          </v-flex>
          <!-- <v-flex lg3 px-1>
             <v-select outlined clearable   dense label="Beat" :items="beatList" item-text="beatName" item-value ="_id" v-model="beat">
            </v-select>  
          </v-flex>
           <v-flex lg3>
             <v-select  clearable  outlined  dense label="Comp" :items="compList" item-text="compName" item-value ="_id" v-model="comp">
            </v-select>  
          </v-flex> -->
        </v-layout>

      </v-flex>
      <!-- {{fromDate}} -->
      <v-flex lg2 pa-1 align-self-start>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="menu11 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
       <v-flex lg2 pa-1 align-self-start>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="records"
          class="elevation-1"
          hide-default-footer
          @click:row="rowClicked"
        >
          <template v-slot:item.time="{ item }">
            <span>{{ new Date(item.time).toLocaleString() }}</span>
          </template>
          <!-- <template v-slot:item.compId="{ item }">
           <v-layout wrap>
               <v-flex xs12 v-for="(item2f , i) in item" :key="i">
                   {{item2f.compName}}
               </v-flex>
           </v-layout>
          </template> -->
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-2>
      <v-flex xs12>
        <v-pagination v-model="page" :length="pagelength" total-visible="10"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fromDate:null,
      toDate: null,
      menu1: false,
      menu2: false,
      appLoading: false,
      ServerError: false,
      headers: [
        { text: "Date", value: "time" },
        //  {
        //   text: "ASW Day ",
        //   align: "start",
        //   sortable: false,
        //   value: "aswDay",
        // },
        {
          text: "Division ",
          align: "start",
          sortable: false,
          value: "divisionId.divisionName",
        },
        {
          text: "Range ",
          align: "start",
          sortable: false,
          value: "rangeId.rangeName",
        },
        // {
        //   text: "Beat ",
        //   align: "start",
        //   sortable: false,
        //   value: "beatId.beatName",
        // },
        // {
        //   text: "Comp ",
        //   align: "start",
        //   sortable: false,
        //   value: "compId",
        // },
        { text: "Animal SPS", sortable: true, value: "animalSps" },
        { text: "Animal Stage", sortable: false, value: "animalStage" },
        { text: "Condition", value: "animalCondition" },
         {
          text: "User",
          align: "start",
          sortable: false,
          value: "userId.firstName",
        },
        // { text: " Not Removed Traps", value: "noOfTrapsNotRemoved" },
      ],
      pagelength: 0,
      page: 1,
      records: [],
      divisionList:[],
      division:null,
      rangeList:[],
      range:null,
      beatList:[],
      beat:null,
      compList:[],
      comp:null,
    };
  },

  mounted() {
    this.getData();
    this.getDivision();
    this.getRange()
    // this.getBeat();
    // this.getComp();
  },

  watch: {
    page() {
      this.getData();
    },
    fromDate()
    {
      
       if(this.fromDate == null || this.fromDate == " ")
       {
          localStorage.setItem("fromdate","null")
       }
       else
       {
       localStorage.setItem("fromdate",this.fromDate)
       }
        this.getData();
    },
    toDate()
    {
          
          if(this.toDate == null || this.toDate == " ")
       {
          localStorage.setItem("toDate","null")
       }
       else
       {
       localStorage.setItem("toDate",this.toDate)
       }


       this.getData();
    },
    division()
    {
       if(this.division == null || this.division == " ")
       {
          localStorage.setItem("division","null")
       }
       else
       {
       localStorage.setItem("division",this.division)
       }

      
       this.getData();
    },
    range()
    {
       if(this.range == null || this.range == " ")
       {
          localStorage.setItem("range","null")
       }
       else
       {
       localStorage.setItem("range",this.range)
       }
       this.getData();
    },
    beat()
    {
      if(this.beat == null || this.beat == " ")
       {
          localStorage.setItem("beat","null")
       }
       else
       {
       localStorage.setItem("beat",this.beat)
       }
       this.getData();
    },
    comp()
    {
      if(this.comp == null || this.comp == " ")
       {
          localStorage.setItem("comp","null")
       }
       else
       {
       localStorage.setItem("comp",this.comp)
       }
       this.getData();
    },
  },
  methods: {
    rowClicked(item) {
      this.$router.push("/animalrescueView?id=" + item._id);
    },

    getData() {
    //  if(localStorage.getItem("fromdate") != "null")
    //  {
    //  this.fromDate = localStorage.getItem("fromdate")
    //  }
    //   if(localStorage.getItem("toDate") != "null")
    //  {
    //  this.toDate = localStorage.getItem("toDate")
    //  }
    //  if(localStorage.getItem("division") != "null")
    //  {
    //  this.division = localStorage.getItem("division")
    //  }
    //  if(localStorage.getItem("range") != "null")
    //  {
    //  this.range = localStorage.getItem("range")
    //  }
    //  if(localStorage.getItem("beat") != "null")
    //  {
    //  this.beat = localStorage.getItem("beat")
    //  }
    //  if(localStorage.getItem("comp") != "null")
    //  {
    //  this.comp = localStorage.getItem("comp")
    //  }
    
     
      



      this.appLoading = true;
      axios({
        url: "/animal/rescue/report",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 10,
          from:this.fromDate,
          toDate:this.toDate,
          divisionId:this.division,
          rangeId:this.range,
          beatId:this.beat,
          compId:this.comp
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.user = response.data.user;
            this.records = response.data.data;
            this.totalLength = response.data.totalLength;
            this.pagelength = Math.ceil(response.data.totalLength / 10);
            // for (let i = 0; i < response.data.data.length; i++) {
            //   this.latt = response.data.data[i].startGps[1];
            //   this.lonn = response.data.data[i].startGps[0];
            //   // console.log("lat",response.data.data[i].location[1])
            //   // console.log("lon",response.data.data[i].location[0])

            //   this.icons = {
            //     // url: require("../../assets/images/whale.svg"),
            //     size: new google.maps.Size(46, 50),
            //     scaledSize: new google.maps.Size(32, 35),
            //     anchor: new google.maps.Point(0, 50),
            //   };
            //   this.marker = new google.maps.Marker({
            //     position: { lat: this.latt, lng: this.lonn },
            //     map: this.gmap,
            //     icon: this.icons,
            //   });

            //   const infowindow = new google.maps.InfoWindow({
            //     // content: String(
            //     //   "<h3>" +
            //     //     this.records[i].boatname +
            //     //     "</h3>" +
            //     //     '<a href="/uploaddetail?id=' +
            //     //     this.records[i]._id +
            //     //     '"><button class="RB " style="color:#d39c00;font-size:12px" >View Details</button></a>'
            //     // ),
            //     maxWidth: 500,
            //   });
            //   this.marker.addListener("click", (eve) => {
            //     infowindow.open(this.map3, this.marker);
            //     infowindow.setPosition(eve.latLng);
            //   });
            // }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  getDivision() {
      this.appLoading = true;
      axios({
        url: "/division/get",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
           this.divisionList = response.data.data
          
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getComp() {
      this.appLoading = true;
      axios({
        url: "/compId/get",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
           this.compList = response.data.data
          
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
getRange() {
      this.appLoading = true;
      axios({
        url: "/range/get",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
           this.rangeList = response.data.data
          
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
getBeat() {
      this.appLoading = true;
      axios({
        url: "/beat/get",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
           this.beatList = response.data.data
          
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },




  },
};
</script>
<style scoped>
.v-data-table ,.v-text-field
{
  font-family:pregular
}
</style>