var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"blue","spinner":"mini-spinner"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_c('h3',{staticStyle:{"border-bottom":"3px solid grey"}},[_vm._v("ANIMAL RESCUE")])])])],1),_c('v-layout',{attrs:{"wrap":"","pt-4":"","justify-end":""}},[_c('v-flex',{attrs:{"lg8":"","pa-1":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"lg6":""}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","label":"Division","items":_vm.divisionList,"item-text":"divisionName","item-value":"_id"},model:{value:(_vm.division),callback:function ($$v) {_vm.division=$$v},expression:"division"}})],1),_c('v-flex',{attrs:{"lg6":"","pl-1":""}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","label":"Range","items":_vm.rangeList,"item-text":"rangeName","item-value":"_id"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-flex',{attrs:{"lg2":"","pa-1":"","align-self-start":""}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","outlined":"","dense":"","clearable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu11 = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-flex',{attrs:{"lg2":"","pa-1":"","align-self-start":""}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","outlined":"","dense":"","clearable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.records,"hide-default-footer":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.time).toLocaleString()))])]}}])})],1)],1),_c('v-layout',{attrs:{"wrap":"","pt-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-pagination',{attrs:{"length":_vm.pagelength,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }